import posthog from 'posthog-js';
import dayjs from 'dayjs';

/**
 * Utility function to handle event tracking with PostHog
 * 
 * @param {string} eventName - The name of the event to be captured
 * @param {object} user - The user object containing user details
 * @param {object} properties - Additional properties to be captured with the event
 */
export const handleEvent = ({ eventName, isDesktop, properties = {} }) => {
    // Determine device type
    const deviceType = isDesktop ? 'desktop' : 'mobile';

    // Capture the event with PostHog
    posthog.capture(eventName, {
        ...properties,
        event_time: dayjs().format(), // Format the event time with dayjs
        device_type: deviceType,
        event_name: eventName,
    });
};
