// questions
export const ANSWERED_QUESTION = "answered_question";
export const EDITED_ANSWER = "edited_answer";
export const CANCELLED_QUESTION_ANSWERING = "cancelled_question_answering";

// journal
export const UPDATED_JOURNAL_NAME = "update_journal_name";

// calendar
export const CHANGED_QUESTION_DAY_VIEW = "changed_question_day_view";
export const CHANGED_QUESTION_MONTH_VIEW = "changed_question_month_view";

// account
export const DELETED_ACCOUNT = "deleted_account";

// coping strategy 
export const COMPLETED_COPING_STRATEGY = "completed_coping_strategy";
export const COMPLETED_COPING_STRATEGY_PREMATURELY = "completed_coping_strategy_prematurely";

// specific to coping statement strategy
export const FINISHED_DEEP_BREATH_STEP = "finished_deep_breath_step";
export const FINISHED_NOTICE_FEELINGS_STEP = "finished_notice_feelings_step";
export const FINISHED_REPEAT_COPING_STATEMENT_STEP = "finished_repeat_coping_statement_step";
export const FINISHED_FOCUS_ACTIVITY_STEP = "finished_focus_activity_step";
export const ABANDONED_COPING_STATEMENT_SURVEY = "abandoned_coping_statement_survey";

// onboarding
export const SIGNING_IN_BY_ANSWERING = "signing_in_by_answering";
export const CREATING_ACCOUNT_BY_ANSWERING = "creating_account_by_answering";

export const SIGNING_IN_DEFAULT = "signing_in_default";
export const CREATING_ACCOUNT_DEFAULT = "creating_account_default";