import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
// @mui
import { Box, CircularProgress, Modal, Typography, IconButton } from '@mui/material';
import Editor from '../../components/editor/Editor';
import apiLayer from '../../services/api-layer/ApiLayer';
import { styled } from '@mui/material/styles';
import { pxToRem } from '../../theme/typography';
import { useStoresContext } from '../../stores/useStoresContext';
import useResponsive from '../../hooks/useResponsive';
import Iconify from '../../components/iconify/Iconify';
import { PATH_APP } from '../../routes/paths';

// ----------------------------------------------------------------------

const filename = "/pages/wiki/ArticlePage.js";

const ArticlePage = () => {
    const { articleId } = useParams();
    const navigate = useNavigate(); // Get the navigate function from react-router

    const { layoutStore } = useStoresContext();
    const isDesktop = useResponsive('up', 'lg');

    const [article, setArticle] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        layoutStore.hideAppHeader();

        return () => {
            layoutStore.reset();
        }
    }, []);

    useEffect(() => {
        const fetchArticle = async () => {
            const response = await apiLayer.getArticleById(articleId);
            setArticle(response.data.article);
            setIsLoading(false);
        };

        fetchArticle();
    }, [articleId]);

    const handleBack = () => {
        navigate(PATH_APP.wiki.root);
    };

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    // Combine title and sections into one string with <br /> tag for spacing
    const combinedContent = `<h1>${article.title}</h1><br />${article.sections.map(section => section.body).join('')}`;

    return (
        <Modal open onClose={handleBack} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ScrollContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, padding: pxToRem(16) }}>
                    <IconButton onClick={handleBack} sx={{ color: 'text.primary' }}>
                        <Iconify icon="ion:arrow-back" />
                        <Typography sx={{ ml: 1 }}>Wiki</Typography>
                    </IconButton>
                </Box>
                <ContentBox>
                    <Editor
                        id={`detail-editor-${article.article_id}`}
                        value={combinedContent}
                        readOnly={true}
                        toolbarActive={false}
                        sx={{ mt: 2, mb: 2, border: 'none', fontWeight: 'bold' }}
                        hideScrollbar={true}
                    />
                </ContentBox>
            </ScrollContent>
        </Modal>
    );
};

const ScrollContent = styled(Box)({
    width: '100%',
    height: '100vh',
    overflow: 'auto',
    padding: pxToRem(16),
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
});

const ContentBox = styled(Box)({
    maxWidth: pxToRem(800),
    width: '100%',
    margin: 'auto',
    fontWeight: 'bold',
    textAlign: 'left',
    padding: `0 ${pxToRem(16)}`, // Add horizontal padding to match content
    flexGrow: 1, // Ensure the content box grows to fill the available space
});

export default ArticlePage;
