// styles.js

// @mui
import { styled } from '@mui/material/styles';
import { Popover, ListItemButton, ListItemIcon, Badge } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// config
import { NAV, ICON } from '../../../config-global';
import { pxToRem } from '../../../theme/typography';

// ----------------------------------------------------------------------

export const StyledItem = styled(ListItemButton, {
  shouldForwardProp: (prop) =>
    prop !== 'active' && prop !== 'open' && prop !== 'variant',
})(({ disabled, open, depth, theme }) => {
  const subItem = depth !== 1;

  return {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textTransform: 'capitalize',
    padding: `${theme.spacing(1)} !important`,
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
    minWidth: pxToRem(80),
    '&:hover': {
      backgroundColor: 'transparent', // Remove background on hover
    },
    // Sub item
    ...(subItem && {
      width: '100%',
      margin: 0,
      paddingRight: 0,
      paddingLeft: theme.spacing(1),
    }),
    // Disabled
    ...(disabled && {
      '&.Mui-disabled': {
        color: theme.palette.action.disabled,
      },
    }),
  };
});

export const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 8,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}));

// ----------------------------------------------------------------------

export const StyledIcon = styled(ListItemIcon)(({ theme }) => ({
  margin: 0,
  flexShrink: 0,
  width: ICON.NAV_ITEM_HORIZONTAL,
  height: ICON.NAV_ITEM_HORIZONTAL,
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  color: 'inherit', // Inherit color to allow activeStyles to apply
}));

// ----------------------------------------------------------------------

export const StyledPopover = styled(Popover)(({ theme }) => ({
  pointerEvents: 'none',
  '& .MuiPopover-paper': {
    width: 160,
    pointerEvents: 'auto',
    padding: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    boxShadow: theme.customShadows.dropdown,
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    ...bgBlur({ color: theme.palette.background.default }),
  },
}));
