import { makeObservable, observable, action, runInAction, computed } from "mobx";
import { Question } from "../../models/question";
import apiLayer from '../../services/api-layer/ApiLayer';
import { sortQuestionsByScoreAscending } from "../../utils/sort";
import dayjs from "dayjs";

class QuestionStore {
    isHydrated = false;
    questionMap = new Map();
    activeQuestion = null;
    selectedQuestion = null;
    startDateBound = null;
    newQuestionIsUnseen = false;
    hasRefreshed = false;

    constructor() {
        makeObservable(this, {
            isHydrated: observable,
            questionMap: observable,
            activeQuestion: observable,
            selectedQuestion: observable,
            startDateBound: observable,
            newQuestionIsUnseen: observable,
            hasRefreshed: observable,
            hydrateForMonthAndYear: action,
            updateFromJson: action,
            setActiveQuestion: action,
            changeSelectedQuestion: action,
            handlePreviousQuestion: action,
            handleNextQuestion: action,
            hardRefresh: action,
            reset: action,
            questions: computed,
            sortedDisplayableQuestions: computed,
            datesForViewableQuestions: computed,
        });
    }

    get questions() {
        return sortQuestionsByScoreAscending(Array.from(this.questionMap.values()));
    }

    get sortedDisplayableQuestions() {
        const activeQuestion = this.activeQuestion;
        if (!activeQuestion) return [];

        return sortQuestionsByScoreAscending(
            this.questions.filter(question =>
                (question.is_active || question.score <= activeQuestion.score) &&
                question.expiration_date
            )
        );
    }

    get datesForViewableQuestions() {
        const dates = new Set();
        this.questions.forEach(question => {
            const date = dayjs(question.expiration_date);
            dates.add(`${date.year()}-${date.month()}`);
        });
        return Array.from(dates);
    }

    async hydrateForMonthAndYear({ isAuthenticated = false, month = null, year = null }) {
        const response = isAuthenticated
            ? await apiLayer.searchQuestions({ month, year })
            : await apiLayer.publicSearchQuestions({ month, year });

        const { questions = [], startDateBound } = response?.data;

        runInAction(() => {
            questions.forEach((question) => {
                this.updateFromJson(question);
            });
            this.startDateBound = startDateBound;
            this.isHydrated = true;
        });
    }

    updateFromJson(json) {
        let question = this.questionMap.get(json.question_id);

        if (question && question.updateFromJson) {
            question.updateFromJson(json);
        } else {
            this.questionMap.set(json.question_id, new Question(json));

            question = this.questionMap.get(json.question_id);
        }

        if (question.is_active) {
            this.setActiveQuestion(question);
        }
    }

    getQuestionById(question_id) {
        return this.questionMap.get(question_id);
    }

    setActiveQuestion = (question) => {
        this.activeQuestion = question;
    }

    changeSelectedQuestion = (question) => {
        this.selectedQuestion = question;

        if (question.question_id === this.activeQuestion?.question_id) {
            this.newQuestionIsUnseen = false;
        }
    }

    jumpToActiveQuestion = () => {
        this.changeSelectedQuestion(this.activeQuestion);
    }

    handlePreviousQuestion = async () => {
        const currentIndex = this.sortedDisplayableQuestions.findIndex(
            (q) => q.question_id === this.selectedQuestion?.question_id
        );

        if (currentIndex > 0) {
            const previousQuestion = this.sortedDisplayableQuestions[currentIndex - 1];
            this.changeSelectedQuestion(previousQuestion);
        }
    };

    handleNextQuestion = async () => {
        const currentIndex = this.sortedDisplayableQuestions.findIndex(
            (q) => q.question_id === this.selectedQuestion?.question_id
        );

        if (currentIndex < this.sortedDisplayableQuestions.length - 1) {
            const nextQuestion = this.sortedDisplayableQuestions[currentIndex + 1];
            this.changeSelectedQuestion(nextQuestion);
        }
    };

    hardRefresh = async () => {
        try {
            if (!this.hasRefreshed) {
                const dailyQuestionCheckRes = await apiLayer.manualNewDailyQuestionCheck();

                if (!dailyQuestionCheckRes?.data.err) {
                    this.hasRefreshed = true;
                }
            }
        } catch (err) {
            console.error(`Error hard refreshing`, err);
        }
    };

    reset = () => {
        this.activeQuestion = null;
        this.selectedQuestion = null;
        this.questionMap = new Map();
        this.isHydrated = false;
        this.startDateBound = null;
        this.newQuestionIsUnseen = false;
        this.hasRefreshed = false;
    }
}

const questionStore = new QuestionStore();

export default questionStore;
