import { makeAutoObservable, computed, action } from "mobx";
import QuestionSubscription from "../question-subscription";

export default class Question {
    question_id = null;
    content = null;
    score = null;
    is_active = false;
    is_expired = false;
    is_pending = false;
    author_name = null;
    expiration_date = null;
    template_answer_info = null;
    article_id = null;
    created_at = null;
    // eagered
    questionSubscription = null; // refers to current user's questionSubscription (not array of question subscriptions)
    article = null; 
    
    // staged
    stagedAnswer = null;

    constructor(json) {
        makeAutoObservable(this, {
            isAnswered: computed,
            setQuestionSubscription: action,
            updateFromJson: action,
            setStagedAnswer: action,
        });

        if (json) {
            this.updateFromJson(json);
        }
    }

    updateFromJson(questionJson) {
        for (const prop in questionJson) {
            if (prop === 'questionSubscriptions' && questionJson[prop]) {
                // only get the current user's questionSubscription
                const questionSubscription = questionJson[prop][0];
                this.questionSubscription = new QuestionSubscription(questionSubscription);
            } else if (prop in questionJson && prop in this) {
                this[prop] = questionJson[prop];
            }
        }
    }

    setQuestionSubscription(subscriptionJson) {
        this.questionSubscription = new QuestionSubscription(subscriptionJson);
    }

    setStagedAnswer(answer) {
        this.stagedAnswer = answer;
    }

    get isAnswered() {
        return (this.questionSubscription?.answer != null || this.stagedAnswer);
    }
}
