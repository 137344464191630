import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { Box, Typography, Button, TextField, Chip, IconButton } from '@mui/material';
import { useStoresContext } from '../../../stores/useStoresContext';
import PropTypes from 'prop-types';
import ClearIcon from '@mui/icons-material/Clear';
import useResponsive from '../../../hooks/useResponsive';
import { pxToRem } from '../../../theme/typography';
import { handleEvent } from '../../../utils/handleEvent';
import { COMPLETED_COPING_STRATEGY_PREMATURELY, FINISHED_NOTICE_FEELINGS_STEP } from '../../../constants/analytics-events';

const exampleResponses = [
    'a stormy sea',
    'weights on my shoulders',
    'a racing heartbeat',
];

const NoticeFeelingsStep = observer(({ onEnd }) => {
    const { copingStatementStore } = useStoresContext();
    const isDesktop = useResponsive('up', 'lg');

    // State variables
    const [responseContent, setResponseContent] = useState('');
    const [responseMethod, setResponseMethod] = useState(null); // 'example' or 'typed'

    const startTimeRef = useRef(null);
    const eventSentRef = useRef(false); // Flag to prevent multiple event sends

    // Set the start time when the component mounts
    useEffect(() => {
        startTimeRef.current = Date.now();

        return () => {
            // Send the event if the component unmounts unexpectedly
            sendAnalyticsEvent();
        };
    }, []); // Empty dependency array ensures this runs once on mount and cleanup on unmount

    // Function to send the analytics event
    const sendAnalyticsEvent = () => {
        if (eventSentRef.current) {
            return; // Event has already been sent, do nothing
        }
        eventSentRef.current = true; // Mark the event as sent

        const endTimestamp = Date.now();
        const durationMs = endTimestamp - startTimeRef.current;
        const durationSeconds = durationMs / 1000;

        const isAnswered = responseContent.trim() !== '';
        const method = responseMethod || 'none';

        handleEvent({
            eventName: FINISHED_NOTICE_FEELINGS_STEP,
            isDesktop,
            properties: {
                duration_seconds: durationSeconds,
                is_answered: isAnswered,
                response_method: method,
            },
        });
    };

    const handleContinue = () => {
        sendAnalyticsEvent(); // Send the event
        copingStatementStore.goToNextStep();
    };

    const handleEnd = () => {
        sendAnalyticsEvent(); // Send the event

        handleEvent({
            eventName: COMPLETED_COPING_STRATEGY_PREMATURELY,
            isDesktop,
            properties: {
                ending_screen: "notice_feelings_step"
            }
        });
        onEnd();
    };

    const handleResponseChange = (e) => {
        setResponseContent(e.target.value);
        setResponseMethod('typed');
    };

    const handleExampleClick = (example) => {
        setResponseContent(example);
        setResponseMethod('example');
    };

    const handleClearResponse = () => {
        setResponseContent('');
        setResponseMethod(null);
    };

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: 2,
            }}
            data-qa-id="notice-feelings-step"
        >
            {/* Top bar with End button */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    onClick={handleEnd}
                    sx={{
                        fontWeight: 'bold',
                        textTransform: 'none',
                        color: "inherit",
                    }}
                    data-qa-id="end-button"
                >
                    Back to my journal
                </Button>
            </Box>

            {/* Content */}
            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: isDesktop ? "center" : "flex-start",
                }}
                data-qa-id="content-container"
            >
                <Typography
                    variant={isDesktop ? 'h3' : 'h4'}
                    align="left"
                    sx={{
                        fontWeight: 'bold',
                        marginBottom: 2,
                        color: "inherit"
                    }}
                    data-qa-id="instruction-text"
                >
                    Take a moment to notice how you're feeling.
                </Typography>

                {/* Sentence completion */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        marginBottom: 2,
                        width: isDesktop ? "60%" : "100%",
                    }}
                >
                    <Typography
                        variant={isDesktop ? 'h3' : 'h4'}
                        align="left"
                        sx={{
                            marginRight: 1,
                            color: "inherit"
                        }}
                        data-qa-id="sentence-prompt-text"
                    >
                        It feels like
                    </Typography>
                    <TextField
                        value={responseContent}
                        onChange={handleResponseChange}
                        placeholder="..."
                        variant="standard"
                        autoFocus={true}
                        inputProps={{
                            style: {
                                fontSize: isDesktop ? pxToRem(32) : pxToRem(16),
                                backgroundColor: 'inherit',
                            },
                        }}
                        InputProps={{
                            endAdornment: responseContent && (
                                <IconButton
                                    aria-label="clear response"
                                    onClick={handleClearResponse}
                                    edge="end"
                                    size="small"
                                    sx={{ padding: 0, color: "#2B3A6C", }}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            ),
                            sx: {
                                '&:before': {
                                    borderBottomColor: '#170161',
                                },
                                '&:hover:not(.Mui-disabled):before': {
                                    borderBottomColor: '#2B3A6C',
                                },
                                '&.Mui-focused:after': {
                                    borderBottomColor: '#170161',
                                },
                            },
                        }}
                        sx={{
                            width: 'auto',
                            fontSize: 'inherit',
                            flexGrow: 0.5,
                            '& .MuiInputBase-input': {
                                fontSize: 'inherit',
                                color: "#2B3A6C"
                            },
                            '& .MuiInputBase-input::placeholder': {
                                color: "#2B3A6C", // Replace with your desired color
                                opacity: 1,       // Override default opacity
                            },
                        }}
                        data-qa-id="response-input"
                    />
                </Box>

                {/* Add more space between the sentence completion and examples */}
                <Box sx={{ height: 16 }} /> {/* Adds 16px of space */}

                {/* Examples */}
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1,
                        marginTop: isDesktop ? 4 : 2,
                    }}
                    data-qa-id="examples-container"
                >
                    {exampleResponses.map((example, index) => (
                        <Chip
                            key={index}
                            label={example}
                            onClick={() => handleExampleClick(example)}
                            sx={{
                                backgroundColor: '#CADFFF',
                                border: responseContent === example ? 'black 2px solid' : 'inherit',
                                fontWeight: 'bold',
                                maxWidth: '100%',
                                whiteSpace: 'normal',
                                height: isDesktop ? pxToRem(32) : "auto",
                                color: "#2B3A6C",
                                '& .MuiChip-label': {
                                    whiteSpace: 'normal',
                                    overflow: 'visible',
                                    textOverflow: 'clip',
                                    paddingTop: 0.5,
                                    paddingBottom: 0.5,
                                },
                                '&:hover': {
                                    backgroundColor: '#A7CAFF', // Hover background color
                                    // border: 'black 2px solid',   // Hover border color
                                },
                            }}
                            data-qa-id={`example-chip-${index}`}
                        />
                    ))}
                </Box>
            </Box>

            {isDesktop && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        width: "50%",
                        margin: `${pxToRem(160)} auto 0 auto`,
                    }}
                    data-qa-id="continue-button-container"
                >
                    <Button
                        variant="contained"
                        fullWidth={"true"}
                        onClick={handleContinue}
                        disabled={!responseContent.trim()}
                        sx={{
                            backgroundColor: '#2B3A6C',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            '&:disabled': {
                                backgroundColor: '#3F4462',
                                '&::before': {
                                    backgroundColor: '#33225B',
                                },
                            },
                            '&:hover': {
                                backgroundColor: '#2B3A6C',
                                '&::before': {
                                    backgroundColor: "#170161",

                                },
                            },
                            '&::before': {
                                backgroundColor: "#170161",

                            },
                        }}
                        data-qa-id="continue-button"
                    >
                        Continue
                    </Button>
                </Box>
            )}

            {!isDesktop && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 2,
                    }}
                    data-qa-id="continue-button-container"
                >
                    <Button
                        variant="contained"
                        fullWidth={true}
                        onClick={handleContinue}
                        disabled={!responseContent.trim()}
                        sx={{
                            backgroundColor: '#2B3A6C',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            '&:disabled': {
                                backgroundColor: '#3F4462',
                                '&::before': {
                                    backgroundColor: '#33225B',
                                },
                            },
                            '&:hover': {
                                backgroundColor: '#2B3A6C',
                                '&::before': {
                                    backgroundColor: "#170161",

                                },
                            },
                            '&::before': {
                                backgroundColor: "#170161",

                            },
                        }}
                        data-qa-id="continue-button"
                    >
                        Continue
                    </Button>
                </Box>
            )}
        </Box>
    );
});

NoticeFeelingsStep.propTypes = {
    onEnd: PropTypes.func.isRequired,
};

export default NoticeFeelingsStep;
