// components/coping-statement-steps/FocusActivityStep/FocusActivityStep.jsx

import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import {
    Box,
    Typography,
    Button,
    Card,
    CardContent,
    LinearProgress,
    Fade,
} from '@mui/material';
import { useStoresContext } from '../../../stores/useStoresContext';
import PropTypes from 'prop-types';
import { styled, keyframes, useTheme } from '@mui/material/styles';
import { pxToRem } from '../../../theme/typography';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { green, red } from '@mui/material/colors';
import { REPEAT_COPING_STATEMENT_STEP } from '../../../constants/coping-strategies/coping-statements';
import useResponsive from '../../../hooks/useResponsive';
import { handleEvent } from '../../../utils/handleEvent';
import {
    COMPLETED_COPING_STRATEGY_PREMATURELY,
    FINISHED_FOCUS_ACTIVITY_STEP,
} from '../../../constants/analytics-events';

// Function to map color names to hex codes
const colorHexMap = {
    Red: '#EF233C',
    Blue: '#2B7DF0',
    Green: '#00B183',
    Yellow: '#FCD45E',
    Black: '#151818',
    White: '#CADFFF',
};

// Helper functions for accessibility
const getLuminance = (hexColor) => {
    let r = parseInt(hexColor.substr(1, 2), 16) / 255;
    let g = parseInt(hexColor.substr(3, 2), 16) / 255;
    let b = parseInt(hexColor.substr(5, 2), 16) / 255;

    r = r <= 0.03928 ? r / 12.92 : ((r + 0.055) / 1.055) ** 2.4;
    g = g <= 0.03928 ? g / 12.92 : ((g + 0.055) / 1.055) ** 2.4;
    b = b <= 0.03928 ? b / 12.92 : ((b + 0.055) / 1.055) ** 2.4;

    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

const getContrastingBackgroundColor = (hexColor) => {
    const luminance = getLuminance(hexColor);

    const contrastWithBlack = (luminance + 0.05) / 0.05;
    const contrastWithWhite = 1.05 / (luminance + 0.05);

    return contrastWithBlack > contrastWithWhite ? '#000000' : '#FFFFFF';
};

// Define keyframes for score animation
const scoreIncrease = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.5); }
  100% { transform: scale(1); }
`;

// Styled components
const StyledCard = styled(Card)(({ theme, isDesktop }) => ({
    position: 'relative',
    minWidth: isDesktop ? pxToRem(680) : pxToRem(300),
    width: isDesktop ? 'inherit' : '100%',
    padding: theme.spacing(2),
    boxShadow: theme.gameCustomShadows.z8,
    borderRadius: theme.shape.borderRadius,
    flexGrow: '0',
    height: isDesktop ? pxToRem(120) : pxToRem(90),
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: '0 !important',
    position: 'relative',
}));

// FeedbackIcon component
const FeedbackIcon = ({ isCorrect }) => {
    const isDesktop = useResponsive('up', 'lg');

    const Icon = isCorrect ? CheckIcon : CloseIcon;
    const backgroundColor = isCorrect ? green[500] : red[500];

    return (
        <Fade in timeout={500}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 2,
                    width: isDesktop ? pxToRem(80) : pxToRem(60),
                    height: isDesktop ? pxToRem(80) : pxToRem(60),
                    borderRadius: '50%',
                    backgroundColor,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Icon sx={{ fontSize: pxToRem(50), color: '#fff' }} />
            </Box>
        </Fade>
    );
};

FeedbackIcon.propTypes = {
    isCorrect: PropTypes.bool.isRequired,
};

// ColorCard component
const ColorCard = ({
    word,
    color,
    backgroundColor,
    textColor,
    dataQaId,
    isTextHidden,
}) => {
    const cardBackgroundColor =
        backgroundColor ||
        getContrastingBackgroundColor(colorHexMap[color] || '#000000');
    const textColorFinal = textColor || colorHexMap[color] || '#000000';

    const isDesktop = useResponsive('up', 'lg');

    return (
        <StyledCard
            data-qa-id={dataQaId}
            sx={{ backgroundColor: cardBackgroundColor }}
            isDesktop={isDesktop}
        >
            <StyledCardContent>
                {!isTextHidden ? (
                    <Fade in={!isTextHidden} timeout={500} appear>
                        <Typography
                            variant="h2"
                            align="center"
                            sx={{
                                color: textColorFinal,
                                fontWeight: 'bold',
                            }}
                        >
                            {word}
                        </Typography>
                    </Fade>
                ) : (
                    <Fade in={isTextHidden} timeout={500}>
                        <Typography
                            variant="h6"
                            align="center"
                            sx={{
                                color: textColorFinal,
                                fontWeight: 'bold',
                            }}
                        >
                            Remember the color
                        </Typography>
                    </Fade>
                )}
            </StyledCardContent>
        </StyledCard>
    );
};

ColorCard.propTypes = {
    word: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    dataQaId: PropTypes.string,
    isTextHidden: PropTypes.bool,
};

// Main component
const FocusActivityStep = observer(({ onEnd }) => {
    const { copingStatementStore } = useStoresContext();

    const isDesktop = useResponsive('up', 'lg');

    const theme = useTheme();

    // Define the list of colors
    const colors = ['Red', 'Blue', 'Green', 'Yellow'];

    const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];

    // Initialize state variables
    const [topCardWord, setTopCardWord] = useState(getRandomColor());
    const [bottomCardWord, setBottomCardWord] = useState(getRandomColor());
    const [bottomCardTextColor, setBottomCardTextColor] = useState(getRandomColor());
    const [topCardBackgroundColor, setTopCardBackgroundColor] = useState(null);
    const [topCardTextColor, setTopCardTextColor] = useState(colorHexMap.Black);
    const [score, setScore] = useState(0);
    const [gameOver, setGameOver] = useState(false);
    const [gameOverReason, setGameOverReason] = useState(null); // 'incorrect' or 'timeout'
    const [answerFeedback, setAnswerFeedback] = useState(null); // 'correct' or 'incorrect'
    const [animateScore, setAnimateScore] = useState(false);
    const [timeLeft, setTimeLeft] = useState(null); // in milliseconds
    const [timerDuration, setTimerDuration] = useState(null); // in milliseconds
    const [showGameOverButtons, setShowGameOverButtons] = useState(false);
    const [isTopTextHidden, setIsTopTextHidden] = useState(false);
    const [isBottomTextHidden, setIsBottomTextHidden] = useState(false);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);

    // To control whether timer decrements
    const [isQuestionActive, setIsQuestionActive] = useState(true);

    const timerIdRef = useRef(null);
    const textHideTimeoutRef = useRef(null);
    const nextQuestionTimeoutRef = useRef(null);
    const previousPairRef = useRef({
        topCardWord: null,
        bottomCardWord: null,
        bottomCardTextColor: null,
    });

    // Analytics tracking variables
    const startTimeRef = useRef(null);
    const eventSentRef = useRef(false); // To prevent multiple event sends
    const [roundsPlayed, setRoundsPlayed] = useState(0); // Track number of rounds played
    const highestScoreRef = useRef(0); // Track the highest score achieved

    // Set the start time when the component mounts
    useEffect(() => {
        startTimeRef.current = Date.now();

        return () => {
            // Send the event if the component unmounts unexpectedly
            sendAnalyticsEvent();

            // Clear all timeouts
            if (timerIdRef.current) clearTimeout(timerIdRef.current);
            if (textHideTimeoutRef.current) clearTimeout(textHideTimeoutRef.current);
            if (nextQuestionTimeoutRef.current)
                clearTimeout(nextQuestionTimeoutRef.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Function to send the analytics event
    const sendAnalyticsEvent = () => {
        if (eventSentRef.current) {
            return; // Event has already been sent, do nothing
        }
        eventSentRef.current = true; // Mark the event as sent

        const endTimestamp = Date.now();
        const durationMs = endTimestamp - startTimeRef.current;
        const durationSeconds = durationMs / 1000;

        const highestScore = highestScoreRef.current;
        const finalGameOverReason = gameOverReason || 'completed';

        handleEvent({
            eventName: FINISHED_FOCUS_ACTIVITY_STEP,
            isDesktop,
            properties: {
                duration_seconds: durationSeconds,
                rounds_played: roundsPlayed,
                highest_score: highestScore,
                game_over_reason: finalGameOverReason,
            },
        });
    };

    // Function to generate new cards
    const generateNewCards = (currentScore) => {
        setAnswerFeedback(null); // Reset feedback
        setIsTopTextHidden(false); // Reset text hidden state
        setIsBottomTextHidden(false);
        setButtonsDisabled(false); // Enable buttons

        // Clear any existing timers
        if (textHideTimeoutRef.current) {
            clearTimeout(textHideTimeoutRef.current);
            textHideTimeoutRef.current = null;
        }

        // Use the updated score
        const scoreToUse = currentScore !== undefined ? currentScore : score;

        // Increase difficulty by changing the top card's background color after score >= 8
        if (scoreToUse >= 8) {
            const newBackgroundColor = colorHexMap[getRandomColor()];
            setTopCardBackgroundColor(newBackgroundColor);
            setTopCardTextColor(getContrastingBackgroundColor(newBackgroundColor));
        } else {
            setTopCardBackgroundColor(null);
            setTopCardTextColor(colorHexMap.Black);
        }

        // Handle text hiding based on score
        if (scoreToUse >= 32) {
            // After score >= 32, hide both top and bottom texts after half a second
            textHideTimeoutRef.current = setTimeout(() => {
                setIsTopTextHidden(true);
                setIsBottomTextHidden(true);
            }, 500);
        } else if (scoreToUse >= 26) {
            // After score >= 26, hide both top and bottom texts after 1 second
            textHideTimeoutRef.current = setTimeout(() => {
                setIsTopTextHidden(true);
                setIsBottomTextHidden(true);
            }, 1000);
        } else if (scoreToUse >= 22) {
            // After score >= 22, hide bottom text after 1 second
            textHideTimeoutRef.current = setTimeout(() => {
                setIsBottomTextHidden(true);
            }, 1000);
        } else if (scoreToUse >= 16) {
            // After score >= 16, hide top text after 1 second
            textHideTimeoutRef.current = setTimeout(() => {
                setIsTopTextHidden(true);
            }, 1000);
        }

        // Update timer duration based on the new score
        let duration = null;
        if (scoreToUse >= 3) {
            if (scoreToUse >= 36) {
                duration = 1000; // 1 second in milliseconds
            } else if (scoreToUse >= 26) {
                duration = 2000;
            } else if (scoreToUse >= 16) {
                duration = 3000;
            } else if (scoreToUse >= 8) {
                duration = 4000;
            } else {
                duration = 6000;
            }
        }
        setTimerDuration(duration);
        setTimeLeft(duration);
        setIsQuestionActive(true);

        // Generate new cards, ensuring they are not the same as the previous pair
        const maxAttempts = 10;
        let attempts = 0;
        let newTopCardWord, newBottomCardWord, newBottomCardTextColor;

        do {
            newTopCardWord = getRandomColor();
            newBottomCardWord = getRandomColor();
            newBottomCardTextColor = getRandomColor();
            attempts++;
        } while (
            attempts < maxAttempts &&
            newTopCardWord === previousPairRef.current.topCardWord &&
            newBottomCardWord === previousPairRef.current.bottomCardWord &&
            newBottomCardTextColor === previousPairRef.current.bottomCardTextColor
        );

        setTopCardWord(newTopCardWord);
        setBottomCardWord(newBottomCardWord);
        setBottomCardTextColor(newBottomCardTextColor);

        // Update the previous pair
        previousPairRef.current = {
            topCardWord: newTopCardWord,
            bottomCardWord: newBottomCardWord,
            bottomCardTextColor: newBottomCardTextColor,
        };
    };

    // Function to reset the game state
    const resetGame = () => {
        // Clear any running timers
        if (timerIdRef.current) {
            clearTimeout(timerIdRef.current);
            timerIdRef.current = null;
        }
        if (textHideTimeoutRef.current) {
            clearTimeout(textHideTimeoutRef.current);
            textHideTimeoutRef.current = null;
        }
        if (nextQuestionTimeoutRef.current) {
            clearTimeout(nextQuestionTimeoutRef.current);
            nextQuestionTimeoutRef.current = null;
        }

        // Update highest score if current score is higher
        if (score > highestScoreRef.current) {
            highestScoreRef.current = score;
        }

        setScore(0);
        setGameOver(false);
        setGameOverReason(null);
        setTimeLeft(null);
        setTimerDuration(null);
        setAnimateScore(false);
        setShowGameOverButtons(false);
        setTopCardBackgroundColor(null);
        setTopCardTextColor(colorHexMap.Black);
        setIsTopTextHidden(false);
        setIsBottomTextHidden(false);
        setButtonsDisabled(false);
        setIsQuestionActive(true);
        previousPairRef.current = {
            topCardWord: null,
            bottomCardWord: null,
            bottomCardTextColor: null,
        };
        generateNewCards(0);
    };

    // Initialize the game
    useEffect(() => {
        resetGame();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Timer countdown
    useEffect(() => {
        if (timeLeft === null || gameOver || !isQuestionActive) {
            return;
        }
        console.log("TIMER:  ", { timeLeft })
        if (timeLeft <= -200) {
            // Time ran out
            setAnswerFeedback('incorrect');
            console.log("SETTING GAME OVER REASON TIMEOUT timeLeft: ", timeLeft)
            setGameOverReason('timeout');

            // Update highest score if current score is higher
            if (score > highestScoreRef.current) {
                highestScoreRef.current = score;
            }

            setGameOver(true);
            setButtonsDisabled(true); // Disable buttons

            // Reveal hidden texts
            setIsTopTextHidden(false);
            setIsBottomTextHidden(false);

            // Clear any pending next question timeout
            if (nextQuestionTimeoutRef.current) {
                clearTimeout(nextQuestionTimeoutRef.current);
                nextQuestionTimeoutRef.current = null;
            }
        } else {
            console.log("DECREMENTING TIMER")
            timerIdRef.current = setTimeout(() => {
                setTimeLeft((prevTime) => prevTime - 100); // Allow timeLeft to go negative
            }, 100);
        }

        // Cleanup function
        return () => {
            if (timerIdRef.current) {
                clearTimeout(timerIdRef.current);
                timerIdRef.current = null;
            }
        };
    }, [timeLeft, gameOver, isQuestionActive]);

    // Handle game over logic
    useEffect(() => {
        if (gameOver) {
            // Clear any pending next question timeout
            if (nextQuestionTimeoutRef.current) {
                clearTimeout(nextQuestionTimeoutRef.current);
                nextQuestionTimeoutRef.current = null;
            }

            // Increment roundsPlayed
            setRoundsPlayed((prevRounds) => prevRounds + 1);

            // Reveal hidden texts when game is over
            setIsTopTextHidden(false);
            setIsBottomTextHidden(false);

            // Update highest score if current score is higher
            if (score > highestScoreRef.current) {
                highestScoreRef.current = score;
            }

            // Delay if the game ended due to incorrect answer or ran out of time
            const timeoutId = setTimeout(() => {
                setShowGameOverButtons(true);
            }, 1000); // 1-second delay

            return () => clearTimeout(timeoutId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameOver]);

    // Function to handle user answer
    const handleAnswer = (userAnswer) => {
        // Log the time when the user clicks their answer
        console.log(`User answered at: ${new Date().toISOString()}`);
        console.log(`Time left: ${timeLeft} ms`);
        console.log("will process answer: ", { gameOver, buttonsDisabled })

        if (gameOver || buttonsDisabled) return;

        // **Immediately stop the timer by setting isQuestionActive to false**
        setIsQuestionActive(false);

        // Disable buttons to prevent multiple clicks
        setButtonsDisabled(true);

        // Clear timers when user answers
        if (timerIdRef.current) {
            clearTimeout(timerIdRef.current);
            timerIdRef.current = null;
        }
        if (textHideTimeoutRef.current) {
            clearTimeout(textHideTimeoutRef.current);
            textHideTimeoutRef.current = null;
        }

        const correctAnswer = topCardWord === bottomCardTextColor;
        const isCorrect = userAnswer === correctAnswer;

        if (isCorrect) {
            // Correct answer
            setAnswerFeedback('correct');

            setScore((prevScore) => {
                const newScore = prevScore + 1;
                setAnimateScore(true);

                // Proceed to next question after a short delay
                nextQuestionTimeoutRef.current = setTimeout(() => {
                    generateNewCards(newScore);
                }, 800);

                return newScore;
            });
        } else {
            // Incorrect answer
            setAnswerFeedback('incorrect');
            setGameOverReason('incorrect');

            // Update highest score if current score is higher
            if (score > highestScoreRef.current) {
                highestScoreRef.current = score;
            }

            setGameOver(true);

            // Reveal hidden texts
            setIsTopTextHidden(false);
            setIsBottomTextHidden(false);

            // Clear any pending next question timeout
            if (nextQuestionTimeoutRef.current) {
                clearTimeout(nextQuestionTimeoutRef.current);
                nextQuestionTimeoutRef.current = null;
            }
        }
    };

    // Animate score when it increases
    useEffect(() => {
        if (animateScore) {
            const timer = setTimeout(() => setAnimateScore(false), 500); // Animation duration
            return () => clearTimeout(timer);
        }
    }, [animateScore]);

    const handleContinue = () => {
        sendAnalyticsEvent(); // Send the event
        copingStatementStore.goToNextStep();
    };

    const handleEnd = () => {
        sendAnalyticsEvent(); // Send the event

        handleEvent({
            eventName: COMPLETED_COPING_STRATEGY_PREMATURELY,
            isDesktop,
            properties: {
                ending_screen: 'focus_activity_step',
            },
        });

        onEnd();
    };

    const handleBackToCopingStatement = () => {
        sendAnalyticsEvent(); // Send the event
        copingStatementStore.goToStep(REPEAT_COPING_STATEMENT_STEP);
    };

    const handleRestart = () => {
        resetGame();
    };

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: `${pxToRem(12)} ${pxToRem(16)}`,
                overflow: 'hidden',
                position: 'relative',
            }}
            data-qa-id="focus-activity-step"
        >
            {/* Top Bar with Back Buttons */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Button
                    onClick={handleBackToCopingStatement}
                    sx={{
                        color: 'inherit',
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    data-qa-id="back-to-coping-statement-button"
                >
                    {isDesktop ? 'Back to coping statement' : 'Coping statement'}
                </Button>
                <Button
                    onClick={handleEnd}
                    sx={{
                        color: 'inherit',
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    data-qa-id="end-button"
                >
                    Back to my journal
                </Button>
            </Box>

            {/* Instructional text */}
            <Typography
                variant="h4"
                sx={{
                    marginTop: 3,
                    textAlign: 'center',
                    margin: `${isDesktop ? pxToRem(72) : pxToRem(64)} auto ${isDesktop ? pxToRem(40) : pxToRem(32)
                        } auto`,
                    color: 'inherit',
                    fontWeight: 'bold',
                    width: '100%',
                }}
                data-qa-id="instructional-game-text"
            >
                {gameOver
                    ? `${gameOverReason === 'timeout' ? 'Ran out of time. ' : ''
                    }${score > 0 ? 'Great job! ' : ''}Your final score is ${score}.`
                    : "Does the top card's word describe the bottom card's text color?"}
            </Typography>

            {/* Content */}
            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    position: 'relative',
                    overflow: 'auto',
                    paddingBottom: 2,
                }}
                data-qa-id="game-content-container"
            >
                {/* Cards Container */}
                <Box
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: isDesktop ? 4 : 2,
                        padding: isDesktop ? pxToRem(32) : pxToRem(16),
                        backgroundColor: '#8BB9FE',
                        borderRadius: pxToRem(8),
                        width: isDesktop ? 'inherit' : '100%',
                    }}
                    data-qa-id="cards-container"
                >
                    {/* Cards Wrapper */}
                    <Box
                        sx={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: isDesktop ? 4 : 2,
                            width: '100%',
                        }}
                        data-qa-id="cards-wrapper"
                    >
                        {/* Top Card */}
                        <ColorCard
                            word={topCardWord}
                            color="Black"
                            backgroundColor={topCardBackgroundColor}
                            textColor={topCardTextColor}
                            dataQaId="top-card"
                            isTextHidden={isTopTextHidden}
                        />

                        {/* Bottom Card */}
                        <ColorCard
                            word={bottomCardWord}
                            color={bottomCardTextColor}
                            dataQaId="bottom-card"
                            isTextHidden={isBottomTextHidden}
                        />

                        {/* Feedback Icon overlaid */}
                        {answerFeedback && (
                            <FeedbackIcon isCorrect={answerFeedback === 'correct'} />
                        )}
                    </Box>

                    {/* Timer */}
                    <Box
                        sx={{
                            width: isDesktop ? '80%' : '100%',
                            marginTop: 2,
                            minHeight: pxToRem(40),
                        }}
                        data-qa-id="timer-container"
                    >
                        {timeLeft !== null && !gameOver ? (
                            <Box sx={{ width: '100%' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: 1,
                                    }}
                                >
                                    <AccessTimeIcon sx={{ marginRight: 1 }} />
                                </Box>
                                <LinearProgress
                                    variant="determinate"
                                    value={(timeLeft / timerDuration) * 100}
                                />
                            </Box>
                        ) : (
                            <Box
                                sx={{ height: pxToRem(40) }}
                                data-qa-id="timer-holder"
                            ></Box>
                        )}
                    </Box>

                    {/* Score Counter */}
                    {!gameOver && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: 2,
                            }}
                            data-qa-id="score-counter"
                        >
                            <StarIcon sx={{ color: 'inherit', marginRight: 1 }} />
                            <Typography
                                variant="h6"
                                sx={{ fontWeight: 'bold', color: 'inherit' }}
                            >
                                Score:
                            </Typography>
                            <Typography
                                variant="h6"
                                sx={{
                                    fontWeight: 'bold',
                                    animation: animateScore
                                        ? `${scoreIncrease} 0.5s`
                                        : 'none',
                                    marginLeft: 0.5,
                                    color: 'inherit',
                                }}
                            >
                                {score}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
            {/* Controls */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: gameOver ? 'column' : 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    gap: 2,
                }}
            >
                {gameOver ? (
                    !showGameOverButtons ? null : (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: isDesktop ? '50%' : '100%',
                            }}
                            data-qa-id="options-container"
                        >
                            {/* Continue Button */}
                            <Button
                                variant="contained"
                                onClick={handleContinue}
                                fullWidth={true}
                                sx={{
                                    backgroundColor: '#2B3A6C',
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    '&:hover': {
                                        backgroundColor: '#2B3A6C',
                                        '&::before': {
                                            backgroundColor: '#170161',
                                        },
                                    },
                                    '&::before': {
                                        backgroundColor: '#170161',
                                    },
                                }}
                            >
                                Continue
                            </Button>

                            {/* Play Again Text Button */}
                            <Button
                                variant="text"
                                onClick={handleRestart}
                                sx={{
                                    marginTop: 2,
                                    color: 'inherit',
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                }}
                            >
                                Play again
                            </Button>
                        </Box>
                    )
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 2,
                            width: isDesktop ? 'fit-content' : '100%',
                        }}
                        data-qa-id="yes-no-container"
                    >
                        <Button
                            variant="contained"
                            size={isDesktop ? 'large' : 'medium'}
                            onClick={() => handleAnswer(true)}
                            disabled={buttonsDisabled}
                            sx={{
                                backgroundColor: '#2B3A6C',
                                fontWeight: 'bold',
                                textTransform: 'none',
                                width: isDesktop ? pxToRem(340) : 'inherit',
                                flex: 1,
                                '&:hover': {
                                    backgroundColor: '#2B3A6C',
                                    '&::before': {
                                        backgroundColor: '#170161',
                                    },
                                },
                                '&::before': {
                                    backgroundColor: '#170161',
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: '#2B3A6C',
                                    '&::before': {
                                        backgroundColor: '#170161',
                                    },
                                },
                            }}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="contained"
                            size={isDesktop ? 'large' : 'medium'}
                            onClick={() => handleAnswer(false)}
                            disabled={buttonsDisabled}
                            sx={{
                                backgroundColor: '#2B3A6C',
                                fontWeight: 'bold',
                                textTransform: 'none',
                                marginLeft: isDesktop ? 8 : 2,
                                width: isDesktop ? pxToRem(340) : 'inherit',
                                flex: 1,
                                '&:hover': {
                                    backgroundColor: '#2B3A6C',
                                    '&::before': {
                                        backgroundColor: '#170161',
                                    },
                                },
                                '&::before': {
                                    backgroundColor: '#170161',
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: '#2B3A6C',
                                    '&::before': {
                                        backgroundColor: '#170161',
                                    },
                                },
                            }}
                        >
                            No
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    );
});

FocusActivityStep.propTypes = {
    onEnd: PropTypes.func.isRequired,
};

export default FocusActivityStep;
