import PropTypes from 'prop-types';
import { AppBar, Toolbar, IconButton, Box } from '@mui/material';
import { bgBlur } from '../../../utils/cssStyles';
import { HEADER } from '../../../config-global';
import { styled } from '@mui/material/styles';
import { pxToRem } from '../../../theme/typography';
import { useState } from 'react';
import { useAuthContext } from '../../../auth/useAuthContext';
import { handleEvent } from '../../../utils/handleEvent';
import { handleError } from '../../../utils/handleError';
import { UPDATED_JOURNAL_NAME } from '../../../constants/analytics-events';
import apiLayer from '../../../services/api-layer/ApiLayer';
import Streak from '../../../components/streak';
import JournalName from '../../../sections/home/journal-name';
import JournalUpdateModal from '../../../sections/home/journal-update-modal';
import { useTheme } from '@mui/material/styles';
import UniversalBackButton from '../../../components/universal-back-button/UniversalBackButton';
import CopingStatementModal from '../../../components/coping-statement-modal/CopingStatementModal';
import Iconify from '../../../components/iconify';
import { useStoresContext } from '../../../stores/useStoresContext';

MobileHeader.propTypes = {
    onClose: PropTypes.func,
    closeIconKey: PropTypes.string,
    simpleMode: PropTypes.bool,
};

export default function MobileHeader({ onClose, closeIconKey = "mingcute:close-fill", simpleMode = false }) {
    const theme = useTheme();
    const { user, isAuthenticated, refreshUser } = useAuthContext();
    const { layoutStore } = useStoresContext();

    const [journalUpdateModalVisible, setJournalUpdateModalVisible] = useState(false);
    const [journalNameSubmitting, setJournalNameSubmitting] = useState(false);
    const [copingStatementModalVisible, setCopingStatementModalVisible] = useState(false);

    const openJournalUpdateModal = () => setJournalUpdateModalVisible(true);
    const closeJournalUpdateModal = () => setJournalUpdateModalVisible(false);

    const openCopingStatementModal = () => setCopingStatementModalVisible(true);
    const closeCopingStatementModal = () => setCopingStatementModalVisible(false);

    const handleSaveJournalUpdate = async ({ journalName }) => {
        try {
            setJournalNameSubmitting(true);

            const journalUpdateRes = await apiLayer.patchUser({
                user_id: user.user_id,
                update: {
                    journal_name: journalName,
                },
            });

            handleEvent({
                eventName: UPDATED_JOURNAL_NAME,
                properties: {
                    user_id: user.user_id,
                    journal_name: journalName,
                }
            });

            if (journalUpdateRes.data.err) {
                handleError({
                    error: journalUpdateRes.data.err,
                    message: `Error updating journal name`,
                    filename: 'src/components/MobileHeader.js',
                    functionName: "handleSaveJournalUpdate",
                });
            } else {
                await refreshUser();
            }
        } catch (err) {
            handleError({
                error: err,
                message: `Error updating journal name`,
                filename: 'src/components/MobileHeader.js',
                functionName: "handleSaveJournalUpdate",
            });
        } finally {
            setJournalNameSubmitting(false);
        }
    };

    return (
        <StyledAppBar data-qa-id="mobile-header">
            <Toolbar
                sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingY: pxToRem(12),
                    minHeight: "fit-content",
                    position: 'relative',
                }}
                data-qa-id="mobile-header-toolbar"
            >
                {/* Left Side: Journal Name */}
                <JournalName
                    journalName={isAuthenticated ? user?.journal_name : "My OCD Journal"}
                    onClick={openJournalUpdateModal}
                    disabled={true} // Keep disabled to maintain aesthetics
                    customStyles={{
                        textAlign: 'left',
                        color: theme.palette.primary.main,
                    }}
                />
                <JournalUpdateModal
                    open={journalUpdateModalVisible}
                    journalName={user?.journal_name}
                    onSave={handleSaveJournalUpdate}
                    onClose={closeJournalUpdateModal}
                    isSubmitting={journalNameSubmitting}
                />



                {/* Right Side: Toolbox Icon and Streak */}
                {isAuthenticated && !simpleMode && (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {/* Date Toggle IconButton */}
                        <IconButton
                            size="small"
                            variant="outlined"
                            sx={{
                                borderRadius: pxToRem(8),
                                border: `${pxToRem(1)} solid`,
                                width: pxToRem(28),
                                height: pxToRem(28),
                                marginRight: pxToRem(8),
                            }}
                            onClick={layoutStore.handleToggleDateView}
                            data-qa-id="open-month-view-button"
                        >
                            <Iconify
                                icon="fa6-solid:calendar"
                                width={pxToRem(12)}
                                height={pxToRem(16)}
                            />
                        </IconButton>

                        {/* Toolbox IconButton */}
                        <IconButton
                            size="small"
                            variant="outlined"
                            onClick={openCopingStatementModal}
                            sx={{
                                borderRadius: pxToRem(8),
                                border: `${pxToRem(1)} solid`,
                                padding: pxToRem(4),
                                width: pxToRem(32),
                                height: pxToRem(28),
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: theme.palette.background.lightNeutral,
                                marginRight: pxToRem(8),
                            }}
                        >
                            <Iconify
                                icon="fa6-solid:toolbox"
                                sx={{ width: pxToRem(16), height: pxToRem(16), color: theme.palette.secondary.contrastText }}
                            />
                        </IconButton>

                        {/* Streak Icon */}
                        <Streak />
                    </Box>
                )}

                {/* Back Button if Close is Provided */}
                {onClose && (
                    <UniversalBackButton
                        onClose={onClose}
                        closeIconKey={closeIconKey}
                        customStyles={{
                            color: theme.palette.primary.main,
                            opacity: 0.5,
                            position: "absolute",
                            padding: `${pxToRem(4)} 0 0 0`,
                            right: pxToRem(16),
                            top: pxToRem(16),
                        }}
                        data-qa-id="mobile-header-close-button"
                    />
                )}
            </Toolbar>

            {copingStatementModalVisible && (
                <CopingStatementModal
                    open={copingStatementModalVisible}
                    onClose={closeCopingStatementModal}
                />
            )}
        </StyledAppBar>
    );
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    height: HEADER.H_MOBILE,
    zIndex: theme.zIndex.appBar + 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'fixed',
    paddingBottom: pxToRem(16),
    '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: pxToRem(2),
        backgroundColor: "#E5B77E",
    },
    ...bgBlur({
        color: theme.palette.background.default,
    }),
}));

