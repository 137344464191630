// routes
import { PATH_APP, PATH_PAGE } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  today: <Iconify
    icon={"fa6-solid:chevron-up"}
  />,
  home: <Iconify
    icon={"fa-solid:home"}
  />,
  conversations: icon('ic_chat'),
  profile: icon('ic_user'),
  settings: <Iconify
    icon={"fa6-solid:gear"}
  />,
};

export const authenticatedNavConfigDesktop = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    key: 'general',
    items: [
      { title: 'home', path: PATH_APP.home, icon: ICONS.home },
      // { title: 'settings', path: PATH_APP.settings, icon: ICONS.settings },
      // { title: 'conversations', path: PATH_APP.conversations.root, icon: ICONS.conversations },
      // { title: 'profile', path: PATH_APP.profile.root, icon: ICONS.profile },
    ],
    footerItems: [
      { title: 'settings', path: PATH_APP.settings, icon: ICONS.settings },
    ]
  },
];

export const authenticatedNavConfigMobile = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    key: 'general',
    items: [
      { title: 'today', path: PATH_APP.home, icon: ICONS.today },
      { title: 'home', path: PATH_APP.home, icon: ICONS.home },
      { title: 'settings', path: PATH_APP.settings, icon: ICONS.settings },
      // { title: 'conversations', path: PATH_APP.conversations.root, icon: ICONS.conversations },
      // { title: 'profile', path: PATH_APP.profile.root, icon: ICONS.profile },
    ],
  },
];

export const nonAuthenticatedNavConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    key: 'general',
    items: [
      { title: 'home', path: PATH_PAGE.home, icon: ICONS.home },
    ],
  },
];
