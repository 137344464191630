import gumDropFireWaving from "../animation-data/gumdrop_fire_waving.json";
import gumDropWriting from "../animation-data/gumdrop_writing.json";
import pageFlip from "../animation-data/page_flip.json";
import swipeRight from "../animation-data/swipe_right.json";

// utils/characterAnimation.js
const animations = {
    GUMDROP_FIRE_WAVING: gumDropFireWaving,
    GUMDROP_WRITING: gumDropWriting,
    PAGE_FLIP: pageFlip,
    SWIPE_RIGHT: swipeRight,
    // Add other animations here
};

export default function getAnimationData(animationName) {
    return animations[animationName] || animations["GUMDROP_FIRE_WAVING"];
}
