// components/coping-statement-modal/CopingStatementModal.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Dialog, DialogContent, Container } from '@mui/material';
import { alpha } from "@mui/material/styles";
import { useStoresContext } from '../../stores/useStoresContext';
import { useTransition, animated } from '@react-spring/web';

import { DeepBreathStep, NoticeFeelingsStep, PreCopingStatementStep, DoneOrRepeatStep, FocusActivityStep, RepeatCopingStatementStep, PreFocusActivityStep } from './coping-statement-steps';
import {
    DEEP_BREATH_STEP,
    NOTICE_FEELINGS_STEP,
    PRE_COPING_STATEMENT_STEP,
    REPEAT_COPING_STATEMENT_STEP,
    PRE_FOCUS_ACTIVITY_STEP,
    FOCUS_ACTIVITY_STEP,
    DONE_OR_REPEAT_STEP,
    COPING_STATEMENT_SURVEY,
    COPING_STATEMENT_STEPS,
} from '../../constants/coping-strategies/coping-statements';

import CopingStatementSurvey from './CopingStatementSurvey';
import { useSettingsContext } from '../settings';
import { pxToRem } from '../../theme/typography';
import useResponsive from '../../hooks/useResponsive';

const CopingStatementModal = observer(({ open, onClose }) => {
    const { copingStatementStore } = useStoresContext();

    const { themeStretch } = useSettingsContext();

    const isDesktop = useResponsive('up', 'lg');

    const handleEnd = () => {
        copingStatementStore.reset();
        onClose();
    };

    const { currentStepIndex } = copingStatementStore;

    const stepName = COPING_STATEMENT_STEPS[currentStepIndex];

    const transitions = useTransition(stepName, {
        from: { opacity: 0, transform: 'translate3d(0,-5%,0)' },
        enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
        leave: { opacity: 0, transform: 'translate3d(0,0,0)' },
        config: { tension: 280, friction: 60 },
    });

    const renderStep = (stepName) => {
        switch (stepName) {
            case DEEP_BREATH_STEP:
                return <DeepBreathStep onEnd={handleEnd} />;
            case NOTICE_FEELINGS_STEP:
                return <NoticeFeelingsStep onEnd={handleEnd} />;
            case PRE_COPING_STATEMENT_STEP:
                return <PreCopingStatementStep onEnd={handleEnd} />;
            case REPEAT_COPING_STATEMENT_STEP:
                return <RepeatCopingStatementStep onEnd={handleEnd} />;
            case PRE_FOCUS_ACTIVITY_STEP:
                return <PreFocusActivityStep onEnd={handleEnd} />;
            case FOCUS_ACTIVITY_STEP:
                return <FocusActivityStep onEnd={handleEnd} />;
            case DONE_OR_REPEAT_STEP:
                return <DoneOrRepeatStep onEnd={handleEnd} />;
            case COPING_STATEMENT_SURVEY:
                return <CopingStatementSurvey onEnd={handleEnd} />;
            default:
                return null;
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleEnd}
            fullScreen
            data-qa-id="coping-statement-modal"
        >
            <DialogContent
                sx={{
                    padding: 0,
                    overflow: 'hidden',
                    backgroundColor: "#77ADFF",
                    backgroundImage: `
                    linear-gradient(to right, ${alpha("#A1D7FF", 0.4)} ${pxToRem(0.6)}, transparent ${pxToRem(0.6)}),
                    linear-gradient(to bottom, ${alpha("#A1D7FF", 0.4)} ${pxToRem(0.6)}, transparent ${pxToRem(0.6)})
                  `,
                    backgroundSize: "20px 20px",
                    color: "#2B3A6C",
                }}
                data-qa-id="coping-statement-modal-content"
            >

                {transitions((style, item) => (
                    <animated.div style={{ ...style, position: 'absolute', width: '100%', height: '100%' }}>
                        <Container
                            maxWidth={themeStretch ? false : "lg"}
                            sx={{
                                height: "100%",
                                padding: `0 0 ${isDesktop ? pxToRem(32) : "0"} 0 !important`,
                            }}
                            data-qa-id={"container-for-steps"}
                        >
                            {renderStep(item)}
                        </Container>
                    </animated.div>
                ))}
            </DialogContent>
        </Dialog>
    );
});

CopingStatementModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default CopingStatementModal;
