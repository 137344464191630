import { makeObservable, observable, action } from "mobx"
import { NEW_USER } from "../../constants/unauthenticated-user-reasons";

class LayoutStore {
    shouldHideAppHeader = false;
    shouldHideHorizontalNav = false;
    isAuthModalOpen = false;
    modalContent = 'auth';
    isUserNew = '';
    registrationPhone = '';

    // handle day/month view
    dateView = 'day';

    constructor() {
        makeObservable(this, {
            shouldHideAppHeader: observable,
            shouldHideHorizontalNav: observable,
            isAuthModalOpen: observable,
            modalContent: observable,
            isUserNew: observable,
            registrationPhone: observable,
            dateView: observable,
            reset: action,
            hideAppHeader: action,
            hideHorizontalNav: action,
            showAppHeader: action,
            showHorizontalNav: action,
            openAuthModal: action,
            closeAuthModal: action,
            setIsUserNew: action,
            setPhoneForRegistration: action,
            handleToggleDateView: action
        });
    }

    reset = () => {
        this.shouldHideAppHeader = false;
        this.shouldHideHorizontalNav = false;
        this.isAuthModalOpen = false;
        this.modalContent = 'auth';
        this.registrationPhone = '';
        this.dateView = 'day'
    }

    handleToggleDateView = () => {
        if (this.dateView === "day") {
            this.dateView = "month";
        } else {
            this.dateView = "day";
        }
    }

    hideAppHeader = () => {
        this.shouldHideAppHeader = true;
    }

    hideHorizontalNav = () => {
        this.shouldHideHorizontalNav = true;
    }

    showAppHeader = () => {
        this.shouldHideAppHeader = false;
    }

    showHorizontalNav = () => {
        this.shouldHideHorizontalNav = false;
    }

    openAuthModal = ({ flowType = "auth", isUserNew = NEW_USER }) => {
        this.isAuthModalOpen = true;
        this.modalContent = flowType;
        this.setIsUserNew(isUserNew);
    }

    closeAuthModal = (modalContentToReset = "auth") => {
        this.isAuthModalOpen = false;
        this.modalContent = modalContentToReset;
    }

    setIsUserNew = (isUserNew = NEW_USER) => {
        this.isUserNew = isUserNew;
    }

    setPhoneForRegistration = (phone) => {
        this.registrationPhone = phone;
    }
}

const layoutStore = new LayoutStore();

export default layoutStore;
