import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import { PATH_APP, PATH_PAGE } from '../../routes/paths';
import { StyledLogoText, StyledLogoBox } from './styles';
import { useAuthContext } from '../../auth/useAuthContext';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, noText, logoTextSX, sx, openNav, ...other }, ref) => {

  const { isAuthenticated } = useAuthContext();

  const logo = (
    <Box
      component="img"
      src="/logo/logo_apple.png"
      sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    />
  );

  if (disabledLink) {
    return (
      <StyledLogoBox>
        {logo}
        {!noText && (
          <StyledLogoText
            variant="h6"
            sx={logoTextSX}
            openNav={openNav}
          >
            My OCD Journal
          </StyledLogoText>
        )}
      </StyledLogoBox>
    );
  }

  return (
    <Link component={RouterLink} to={isAuthenticated ? PATH_APP.home : PATH_PAGE.home} sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
      <StyledLogoBox>
        {logo}
        {!noText && (
          <StyledLogoText
            variant="h6"
            sx={logoTextSX}
            openNav={openNav}
          >
            My OCD Journal
          </StyledLogoText>
        )}
      </StyledLogoBox>
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
  noText: PropTypes.bool,
  logoTextSX: PropTypes.object,
  customSize: PropTypes.number,
  openNav: PropTypes.bool,
};

export default Logo;
