export const COPING_STATEMENTS = [
  "I have to live with the uncertainty",
  "I know I’m obsessing when my thoughts start with 'What if?'",
  "Analyzing my thoughts will only make things worse",
  "My obsessions pass when I don’t act on the fear. This one will pass too.",
  "My family and friends can’t really assure me of anything, and when they do, I don’t believe them anyway",
];

export const DEEP_BREATH_STEP = 'deep_breath_step';
export const NOTICE_FEELINGS_STEP = 'notice_feelings_step';
export const PRE_COPING_STATEMENT_STEP = 'pre_coping_statement_step';
export const REPEAT_COPING_STATEMENT_STEP = 'repeat_coping_statement_step';
export const PRE_FOCUS_ACTIVITY_STEP = 'pre_focus_activity_step';
export const FOCUS_ACTIVITY_STEP = 'focus_activity_step';
export const DONE_OR_REPEAT_STEP = 'done_or_repeat_step';
export const COPING_STATEMENT_SURVEY = 'coping_statement_survey';

export const COPING_STATEMENT_STEPS = [
  DEEP_BREATH_STEP,
  NOTICE_FEELINGS_STEP,
  PRE_COPING_STATEMENT_STEP,
  REPEAT_COPING_STATEMENT_STEP,
  PRE_FOCUS_ACTIVITY_STEP,
  FOCUS_ACTIVITY_STEP,
  DONE_OR_REPEAT_STEP,
  COPING_STATEMENT_SURVEY,
];
