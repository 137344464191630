import PropTypes from 'prop-types';
import { memo } from 'react';
// @mui
import { Stack } from '@mui/material';
// utils
import { hideScrollbarY } from '../../../utils/cssStyles';
//
import NavList from './NavList';
import { pxToRem } from '../../../theme/typography';

// ----------------------------------------------------------------------

NavSectionHorizontal.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.array,
  unreadMessageCount: PropTypes.number,
};

function NavSectionHorizontal({ data, unreadMessageCount, sx, ...other }) {
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        mx: 'auto',
        ...hideScrollbarY,
        ...sx,
      }}
      data-qa-id="nav-section-horizontal"
      {...other}
    >
      {data.map((group) => (
        <Items key={group.key} items={group.items} unreadMessageCount={unreadMessageCount} />
      ))}
    </Stack>
  );
}

export default memo(NavSectionHorizontal);

// ----------------------------------------------------------------------

Items.propTypes = {
  items: PropTypes.array,
  unreadMessageCount: PropTypes.number,
};

function Items({ items, unreadMessageCount }) {
  return (
    <>
      {items.map((list) => (
        <NavList key={list.title + list.path} data={list} depth={1} unreadMessageCount={unreadMessageCount} hasChild={!!list.children} />
      ))}
    </>
  );
}
