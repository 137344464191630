import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { handleError } from '../utils/handleError';

const filename = "/stores/StoresContext.js";

export const StoresContext = createContext();

export function StoresProvider({ stores, children }) {
    const [isReady, setIsReady] = useState(false);

    const initialize = useCallback(async () => {
        try {
            // hydrate necessary stores and any other steps

            setIsReady(true);
        } catch (error) {
            handleError({
                error: error,
                message: "Error initializing stores",
                filename,
                functionName: "initialize",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        initialize();
    }, [initialize]);

    const resetStores = () => {
        stores.questionStore.reset();
        stores.matchStore.reset();
        stores.layoutStore.reset();
        stores.characterAnimationStore.reset();
        stores.swipeStore.reset();
        stores.copingStatementStore.reset();
    }

    const memoizedValue = useMemo(
        () => ({
            isReady,
            questionStore: isReady ? stores.questionStore : null,
            matchStore: isReady ? stores.matchStore : null,
            layoutStore: stores.layoutStore,
            characterAnimationStore: stores.characterAnimationStore,
            swipeStore: stores.swipeStore,
            copingStatementStore: stores.copingStatementStore,
            resetStores: resetStores,
        }),
        [isReady, stores.questionStore, stores.matchStore, stores.layoutStore, stores.characterAnimationStore, stores.swipeStore, stores.copingStatementStore,]
    );

    return <StoresContext.Provider value={memoizedValue}>{children}</StoresContext.Provider>;
}