import { alpha } from '@mui/material/styles';
import { pxToRem } from '../typography';
import useResponsive from '../../hooks/useResponsive';

const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];

export default function Button(theme) {
  const isLight = theme.palette.mode === 'light';

  const isDesktop = useResponsive("up", "lg");

  const rootStyle = (ownerState) => {
    const containedVariant = ownerState.variant === 'contained';
    const outlinedVariant = ownerState.variant === 'outlined';
    const textVariant = ownerState.variant === 'text';
    const softVariant = ownerState.variant === 'soft';
    const smallSize = ownerState.size === 'small';
    const largeSize = ownerState.size === 'large';


    const highlightWrapper = {
      position: 'relative',
      display: 'inline-block',
      '&::before': {
        content: '""',
        position: 'absolute',
        bottom: containedVariant ? -4 : -5,
        left: 0,
        right: 0,
        height: containedVariant ? 12 : 9,
        backgroundColor: theme.palette.button[containedVariant ? "containedBorderStroke" : "outlinedBorderStroke"],
        borderBottomLeftRadius: theme.shape.borderRadius * 2,
        borderBottomRightRadius: theme.shape.borderRadius * 2,
        zIndex: -1,

      },
    };

    const highlightHoverWrapper = {
      '&::before': {
        content: '""',
        position: 'absolute',
        bottom: containedVariant ? -4 : -5,
        left: 0,
        right: 0,
        height: containedVariant ? 12 : 9,
        backgroundColor: theme.palette.button.hover[containedVariant ? "containedBorderStroke" : "outlinedBorderStroke"],
        borderBottomLeftRadius: theme.shape.borderRadius * 2,
        borderBottomRightRadius: theme.shape.borderRadius * 2,
        zIndex: -1,

      },
    };

    // apply when button pressed
    const highlightActiveWrapper = {
      '&::before': {
        content: '""',
        position: 'absolute',
        bottom: containedVariant ? -4 : -5,
        left: 0,
        right: 0,
        height: 0,
        backgroundColor: theme.palette.button[containedVariant ? "containedBorderStroke" : "outlinedBorderStroke"],
        borderBottomLeftRadius: theme.shape.borderRadius * 2,
        borderBottomRightRadius: theme.shape.borderRadius * 2,
        zIndex: -1,
      },
    };


    const disabledHighlightWrapper = {
      '&::before': {
        content: '""',
        position: 'absolute',
        bottom: containedVariant ? -4 : -5,
        left: 0,
        right: 0,
        height: containedVariant ? 12 : 9,
        backgroundColor: theme.palette.button.disabled[containedVariant ? "containedBorderStroke" : "outlinedBorderStroke"],
        borderBottomLeftRadius: theme.shape.borderRadius * 2,
        borderBottomRightRadius: theme.shape.borderRadius * 2,
        zIndex: -1,
      },
    };

    const burgundyPrimaryStyle = {
      ...(containedVariant && {
        ...highlightWrapper,
        backgroundColor: theme.palette.button.containedBackground,
        color: theme.palette.common.white,
        ...(isDesktop
          ? {
            '&:hover': {
              ...highlightHoverWrapper,
              backgroundColor: theme.palette.button.hover.containedBackground,
              boxShadow: 'inherit',
            },
          }
          : {
            '&:hover': {
              ...highlightWrapper,
              backgroundColor: theme.palette.button.containedBackground,
              color: theme.palette.common.white,
            },
          }),
        '&:active': {
          ...highlightActiveWrapper,
          transform: `translateY(${pxToRem(4)}) translateZ(0)`,
        },
      }),
      ...(outlinedVariant && {
        ...highlightWrapper,
        backgroundColor: theme.palette.button.outlinedBackground,
        borderColor: theme.palette.secondary.contrastText,
        color: theme.palette.secondary.contrastText,
        ...(isDesktop
          ? {
            '&:hover': {
              ...highlightHoverWrapper,
              backgroundColor: theme.palette.button.hover.outlinedBackground,
              borderColor: theme.palette.secondary.contrastText,
              color: theme.palette.secondary.contrastText,
            },
          }
          : {
            '&:hover': {
              ...highlightWrapper,
              backgroundColor: theme.palette.button.outlinedBackground,
              borderColor: theme.palette.secondary.contrastText,
              color: theme.palette.secondary.contrastText,
            },
          }),
        '&:active': {
          ...highlightActiveWrapper,
          transform: `translateY(${pxToRem(4)}) translateZ(0)`,
        },
      }),
    };

    const burgundyDisabledPrimaryStyle = {
      ...(containedVariant && {
        ...disabledHighlightWrapper,
        backgroundColor: theme.palette.button.disabled.containedBackground,
        color: theme.palette.common.white,
      }),
      ...(outlinedVariant && {
        ...disabledHighlightWrapper,
        backgroundColor: theme.palette.button.disabled.outlinedBackground,
        borderColor: theme.palette.secondary.contrastText,
        color: theme.palette.secondary.contrastText,
      }),
    };

    const defaultStyle = {
      ...((ownerState.color === 'inherit') && {
        ...(containedVariant && {
          color: theme.palette.grey[800],
          '&:hover': {
            boxShadow: theme.customShadows.z8,
            backgroundColor: theme.palette.grey[400],
          },
        }),
        ...(outlinedVariant && {
          borderColor: alpha(theme.palette.grey[500], 0.32),
          '&:hover': {
            borderColor: theme.palette.text.primary,
            backgroundColor: theme.palette.action.hover,
          },
        }),
        ...(textVariant && {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        }),
        ...(softVariant && {
          color: theme.palette.text.primary,
          backgroundColor: alpha(theme.palette.grey[500], 0.08),
          '&:hover': {
            backgroundColor: alpha(theme.palette.grey[500], 0.24),
          },
        }),
      }),
    };

    const colorStyle = COLORS.map((color) => ({
      ...(ownerState.color === color && {
        ...(containedVariant && {
          '&:hover': {
            boxShadow: 'inherit',
          },
        }),
        ...(softVariant && {
          color: theme.palette[color][isLight ? 'dark' : 'light'],
          backgroundColor: alpha(theme.palette[color].main, 0.16),
          '&:hover': {
            backgroundColor: alpha(theme.palette[color].main, 0.32),
          },
        }),
      }),
    }));

    const disabledState = {
      '&.Mui-disabled': {
        ...burgundyDisabledPrimaryStyle
      },
    };

    const size = {
      ...(smallSize && {
        height: 30,
        fontSize: 13,
        ...(softVariant && {
          padding: '4px 10px',
        }),
      }),
      ...(largeSize && {
        height: 48,
        fontSize: 15,
        ...(softVariant && {
          padding: '8px 22px',
        }),
      }),
    };

    return [burgundyPrimaryStyle, defaultStyle, ...colorStyle, disabledState, size];
  };

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => rootStyle(ownerState),
      },
    },
  };
}
