// components/BaseModal.js
import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Card, Box, Typography, Stack, styled } from '@mui/material';
import { pxToRem } from '../../theme/typography';
import useResponsive from '../../hooks/useResponsive';
import CustomHeader from '../custom-header/CustomHeader';
import { MobileHeader } from '../../layouts/app/header';
import { HEADER } from '../../config-global';

const BaseModal = ({ open, onClose, title, subheaderText, description, children, customStyles, closeIconKey, fullScreen = false }) => {
    const isDesktop = useResponsive('up', 'lg');

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            fullScreen={fullScreen}
            PaperProps={{
                sx: {
                    overflow: 'hidden', // Ensure no horizontal overflow
                    ...customStyles,
                },
            }}
            data-qa-id={"base-modal"}
        >
            <StyledCard isDesktop={isDesktop} isFullScreen={fullScreen} data-qa-id={"base-modal-card"}>
                <StyledRoot isDesktop={isDesktop} data-qa-id="modal-root-container">
                    {fullScreen && (
                        <MobileHeader
                            onClose={onClose}
                            simpleMode={true}
                            closeIconKey="mingcute:close-fill"
                        />
                    )}
                    <CustomHeader
                        titleNode={(
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", }}>
                                {subheaderText && (
                                    <Typography variant="caption" sx={{ textAlign: 'left', margin: `${pxToRem(12)} 0` }}>
                                        {subheaderText}
                                    </Typography>
                                )}
                                <Typography variant="h4" sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                    {title}
                                </Typography>
                            </Box>
                        )}
                        customStyles={{
                            marginTop: fullScreen ? pxToRem(HEADER.H_MOBILE) : "inherit",
                        }}
                    onClose={!fullScreen ? onClose : undefined}
                    closeIconKey={closeIconKey}
                    />
                    <StyledContent sx={{ marginTop: 2 }} data-qa-id="modal-content-container">
                        {description && (
                            <Typography variant="body1" sx={{ textAlign: 'left', width: "100%" }}>
                                {description}
                            </Typography>
                        )}
                        <Stack spacing={isDesktop ? 4 : 2} sx={{ mt: isDesktop ? 2 : 0, mb: 4, width: "100%" }} data-qa-id="modal-content-stack">
                            {children}
                        </Stack>
                    </StyledContent>
                </StyledRoot>
            </StyledCard>
        </Dialog>
    );
};

BaseModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    subheaderText: PropTypes.string,
    description: PropTypes.string,
    children: PropTypes.node,
    customStyles: PropTypes.object,
    closeIconKey: PropTypes.string,
};

BaseModal.defaultProps = {
    closeIconKey: "mingcute:close-fill",
};

const StyledCard = styled(Card)(({ theme, isDesktop, isFullScreen }) => ({
    maxWidth: pxToRem(800), // Increase the width of the card
    margin: 'auto',
    borderRadius: isDesktop ? theme.shape.borderRadius * 2 : isFullScreen ? 0 : theme.shape.borderRadius * 2,
    boxShadow: theme.shadows[0],
    height: isDesktop ? "inherit" : "100%",
    width: '100%', // Ensure it takes full width
    backgroundColor: theme.palette.background.paper,
    zIndex: 1, // Ensure card is above the highlight wrapper
    border: isDesktop ? `${pxToRem(2)} solid ${theme.palette.card.borderStroke}` : (isFullScreen ? "inherit" : `${pxToRem(2)} solid ${theme.palette.card.borderStroke}`),
}));

const StyledRoot = styled('main')(({ theme, isDesktop }) => ({
    padding: isDesktop ? theme.spacing(4, 6, 0, 6) : theme.spacing(4, 2, 0, 2),
}));

const StyledContent = styled('div')(() => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));

export default BaseModal;
