import { useContext } from 'react';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Button, Typography, Box } from '@mui/material';
// context
import { RegistrationContext } from '../../RegistrationContext';
// components
import FormProvider, { RHFTextField } from '../../../../../components/hook-form';
// styles
import { StyledRoot, StyledContent } from './styles';
//hooks
import useResponsive from '../../../../../hooks/useResponsive';
import { handleError } from '../../../../../utils/handleError';
import { CustomHeader } from '../../../../../components/custom-header';
import { pxToRem } from '../../../../../theme/typography';
import { useStoresContext } from '../../../../../stores/useStoresContext';
import { NEW_USER } from '../../../../../constants/unauthenticated-user-reasons';
import { MobileHeader } from "../../../../../layouts/app/header";
import MobileBottomBar from "../../../../../layouts/app/bottom/MobileBottomBar";
import { HEADER } from '../../../../../config-global';

// ----------------------------------------------------------------------
const filename = "/sections/auth/register/forms/name-form/NameForm.js";

const SPACING = 8;

export default function NameForm({ phone }) {
    const { state, updateFields } = useContext(RegistrationContext);

    const isDesktop = useResponsive('up', 'lg');

    const { layoutStore } = useStoresContext();

    const NameSchema = Yup.object().shape({
        first_name: Yup.string().required('First name required').max(100, 'Maximum 100 characters'),
    });

    const defaultValues = {
        first_name: state.first_name ?? '',
    };

    const methods = useForm({
        resolver: yupResolver(NameSchema),
        defaultValues,
        mode: "onChange",
    });

    const {
        reset,
        handleSubmit,
        formState: { isValid },
    } = methods;

    const onSubmit = async (data) => {
        try {
            updateFields({ first_name: data.first_name, phone: phone });

        } catch (error) {
            handleError({
                error: error,
                message: `Error submitting name with phone`,
                filename,
                functionName: "onSubmit",
            });

            reset();
        }
    };

    const handleClose = () => {
        layoutStore.closeAuthModal();
    };

    const customHeaderTitleNode = (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>
            <Typography variant={isDesktop ? "caption" : "body1"} sx={{ textAlign: 'left', margin: `${pxToRem(12)} 0` }}>
                {layoutStore.isUserNew === NEW_USER ? "CREATE ACCOUNT" : "SIGN IN"}
            </Typography>
            <Typography variant="h4" sx={{ display: 'flex', alignItems: 'flex-end', }}>
                Who does this journal belong to?
            </Typography>
        </Box>
    )

    return (
        <StyledRoot isDesktop={isDesktop} data-qa-id="name-root-container">
            {isDesktop ? (
                <CustomHeader
                    titleNode={customHeaderTitleNode}
                />

            ) : (
                <MobileHeader
                    onClose={handleClose}
                    closeIconKey="mingcute:close-fill"
                />
            )}
            <StyledContent data-qa-id="name-styled-content-container">
                <Stack spacing={isDesktop ? 4 : 2} sx={{ mt: isDesktop ? 2 : 0, width: 1, paddingTop: isDesktop ? "0" : pxToRem(HEADER.H_MOBILE + SPACING), }} data-qa-id="name-content-container">
                    {!isDesktop && customHeaderTitleNode}

                    <FormProvider
                        methods={methods}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Stack direction={"column"} justifyContent={"center"} spacing={2} sx={{ width: 1, margin: isDesktop ? "auto" : "inherit" }} data-qa-id="name-form-container">
                            <RHFTextField
                                name="first_name"
                                label="First name"
                                autoFocus={true}
                            />

                            {isDesktop ? (
                                <Button
                                    fullWidth
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    disabled={!isValid}
                                    data-qa-id="name-next-button"
                                >
                                    Continue
                                </Button>
                            ) : (
                                <MobileBottomBar>
                                    <Button
                                        fullWidth
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        disabled={!isValid}
                                        data-qa-id="name-next-button"
                                    >
                                        Continue
                                    </Button>
                                </MobileBottomBar>
                            )}


                        </Stack>
                    </FormProvider>
                </Stack>
            </StyledContent>
        </StyledRoot>
    )
}
