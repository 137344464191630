import { Navigate, useRoutes } from 'react-router-dom';
import AuthGuard from '../auth/AuthGuard';
import AdminGuard from '../auth/AdminGuard';
import {
    AdminPage,
    HomePage,
    AccountPage,
    Page403,
    NoAccessProfilePage,
    NoMatchFoundPage,
    WikiPage,
} from './elements';
import AppLayout from '../layouts/app';
import CompactLayout from '../layouts/compact/CompactLayout';
import { PATH_AFTER_LOGIN } from '../config-global';
import AuthGoogleCallback from '../auth/AuthGoogleCallback'; // Add this import
import GuestGuard from '../auth/GuestGuard';
import ArticlePage from '../sections/wiki/ArticlePage';

export default function Router() {
    return useRoutes([
        {
            path: '/',
            element: (
                <GuestGuard>
                    <AppLayout />
                </GuestGuard>
            ),
            children: [
                { path: '', element: <HomePage /> },
            ],
        },
        {
            path: 'app',
            element: (
                <AuthGuard>
                    <AppLayout />
                </AuthGuard>
            ),
            children: [
                { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
                { path: 'home', element: <HomePage /> },
                { path: 'wiki', element: <WikiPage /> },
                { path: 'wiki/:articleId', element: <ArticlePage /> },
                // {
                //     path: 'conversations',
                //     children: [
                //         { element: <ConversationsPage />, index: true },
                //         { path: ':matchId', element: <ConversationsDetailView /> },
                //     ],
                // },
                // {
                //     path: 'profile',
                //     children: [
                //         { element: <Profile />, index: true },
                //         { path: ':username', element: <Profile /> },
                //     ],
                // },
                { path: 'settings', element: <AccountPage /> },
            ]
        },
        {
            path: '/admin',
            element: (
                <AdminGuard>
                    <AdminPage />
                </AdminGuard>
            ),
        },
        {
            element: <CompactLayout />,
            children: [
                { path: '403', element: <Page403 /> },
                { path: 'no-profile-access', element: <NoAccessProfilePage /> },
                { path: 'no-match-found', element: <NoMatchFoundPage /> },
            ],
        },
        { path: '/auth/google/callback', element: <AuthGoogleCallback /> },
    ]);
}
