// stores/CopingStatementStore.js
import { makeObservable, observable, action } from 'mobx';
import { COPING_STATEMENTS, COPING_STATEMENT_STEPS } from '../../constants/coping-strategies/coping-statements';

class CopingStatementStore {
    copingStatementStrategy = {
        copingStatements: COPING_STATEMENTS,
    };

    currentStepIndex = 0; // Tracks the current step

    constructor() {
        makeObservable(this, {
            copingStatementStrategy: observable,
            currentStepIndex: observable,
            getCopingStatements: action,
            goToNextStep: action,
            goToPreviousStep: action, // Added
            goToStep: action,
            reset: action,
        });
    }

    getCopingStatements = () => {
        return this.copingStatementStrategy?.copingStatements || [];
    };

    goToNextStep = () => {
        if (this.currentStepIndex < COPING_STATEMENT_STEPS.length - 1) {
            this.currentStepIndex += 1;
        }
    };

    goToPreviousStep = () => {
        if (this.currentStepIndex > 0) {
            this.currentStepIndex -= 1;
        }
    };

    goToStep = (stepName) => {
        const index = COPING_STATEMENT_STEPS.indexOf(stepName);
        if (index !== -1) {
            this.currentStepIndex = index;
        }
    };

    reset = () => {
        this.currentStepIndex = 0;
    };
}

const copingStatementStore = new CopingStatementStore();

export default copingStatementStore;
